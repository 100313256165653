<template>
  <FilterBarBase btnType="tertiary" btnText="가입내역 확인" @onClickSearch="onClickSearch">
    <dl>
      <dt>ID (사업자 번호)</dt>
      <dd>
        <div class="group_form">
          <!-- 일단 문자도 입력 가능하게 -->
          <Input class="w134" :maxLength="3" :value.sync="corprateNum01" />
          <span class="txt_view_c w20">-</span>
          <Input class="w72" :maxLength="2" :value.sync="corprateNum02" />
          <span class="txt_view_c w20">-</span>
          <Input
            class="w134"
            :maxLength="5"
            :value.sync="corprateNum03"
            @onEnterKey="onClickSearch"
          />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import { mapState } from "vuex";

export default {
  name: "JoinInquiryFilterBar",
  components: {
    FilterBarBase,
    Input,
  },
  data() {
    return {
      corprateNum01: "",
      corprateNum02: "",
      corprateNum03: "",
    };
  },
  methods: {
    alert(text) {
      this.$emit("alert", text);
    },
    onClickSearch() {
      const corprateNum01 = this.corprateNum01;
      const corprateNum02 = this.corprateNum02;
      const corprateNum03 = this.corprateNum03;
      // const emailName = this.emailName;
      // const emailDomain = this.emailDomain;
      const corprateNumValue = corprateNum01 + "-" + corprateNum02 + "-" + corprateNum03;
      // const emailValue = emailName+'@'+emailDomain;

      if (
        corprateNum01.trim() === "" ||
        corprateNum02.trim() === "" ||
        corprateNum03.trim() === ""
      ) {
        this.alert("사업자 번호를 정확히 입력해주세요");
        return;
      }

      // if(emailName.trim().replace(/[^a-zA-Z]/g,'') === '' || emailDomain.trim().replace(/[^a-zA-Z]/g,'') === ''){
      //   this.alert('이메일을 정확히 입력해주세요');
      //   return
      // }

      // if(!this.checkBizID(corprateNumValue)){
      //   this.alert('사업자등록번호가 유효하지 않습니다.');
      //   return
      // }

      // 여기서 데이터 가져오지 않고 최상위에서 api 호출하게끔 변경.
      this.$emit("onCheckJoinInfo", corprateNumValue); //emailValue
    },
    checkBizID(bizID) {
      // bizID는 숫자만 10자리로 해서 문자열로 넘긴다.
      var checkID = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5, 1);
      var tmpBizID,
        i,
        chkSum = 0,
        c2,
        remander;
      var result;
      bizID = bizID.replace(/-/gi, "");
      for (i = 0; i <= 7; i++) {
        chkSum += checkID[i] * bizID.charAt(i);
      }
      c2 = "0" + checkID[8] * bizID.charAt(8);
      c2 = c2.substring(c2.length - 2, c2.length);
      chkSum += Math.floor(c2.charAt(0)) + Math.floor(c2.charAt(1));
      remander = (10 - (chkSum % 10)) % 10;
      if (Math.floor(bizID.charAt(9)) == remander) {
        result = true; // OK!
      } else {
        result = false;
      }
      return result;
    },
  },
};
</script>
