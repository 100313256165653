<template>
  <ul class="list_check">
    <li v-for="item in companies" :key="item.companyCode">
      <div class="check_comm">
        <input
          :id="item.companyCode"
          v-model="afficodeListSync"
          type="checkbox"
          :value="item"
          :disabled="getIsDisabled(item.companyCode)"
        />
        <label :for="item.companyCode">
          <span class="inner_label"
            >{{ item.companyName }}<span class="ico_purchase ico_checkbox"
          /></span>
        </label>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "JoinInquiryPartnerList",
  props: {
    partnerJoins: Array,
    afficodeList: Array,
    companies: Array,
  },
  computed: {
    // companies() {
    //   return companies;
    // },
    afficodeListSync: {
      get() {
        return this.afficodeList;
      },
      set(value) {
        this.$emit("update:afficodeList", value);
      },
    },
  },
  methods: {
    getIsDisabled(affiCode) {
      if (!this.partnerJoins || this.partnerJoins.length === 0) return false;

      const obj = this.partnerJoins.find((item) => item.affiCode === affiCode);
      const isDisabled = !!obj;
      return isDisabled;
    },
  },
};
</script>

<style scoped>
.list_check {
  overflow: hidden;
}
.list_check li {
  float: left;
  position: relative;
  width: 307px;
  height: 64px;
  margin-left: 4px;
}
.list_check li:nth-child(n + 5) {
  margin-top: 4px;
}
.list_check li:nth-child(4n + 1) {
  margin-left: 0;
}
.list_check .check_comm {
  width: 100%;
  height: 100%;
}
.list_check .check_comm input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border: 0 none;
  opacity: 0.01;
  filter: alpha(opacity=1);
  cursor: pointer;
}
.list_check .check_comm label {
  display: block;
  height: 62px;
  padding: 0 30px;
  border: 1px solid #e1e1e1;
  font-size: 0;
  background-color: #fffbed;
  white-space: nowrap;
}
.list_check .check_comm label:after {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.list_check .check_comm .inner_label {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  white-space: normal;
}
.list_check .check_comm .ico_checkbox {
  position: absolute;
  top: 50%;
  left: auto;
  right: 14px;
  margin-top: -8px;
}
.check_comm input:checked + label {
  height: 60px;
  border: 2px solid #ffcd00;
}
.check_comm input:checked + label .inner_label {
  color: #222;
}
.check_comm input:checked + label .ico_checkbox {
  display: block;
}
.check_comm input:disabled + label {
  background-color: #fff;
  color: #888;
}
.check_comm input:disabled + label .ico_checkbox {
  display: none;
}
</style>
