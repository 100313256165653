<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">회원가입</h2>
      </div>
      <div class="body_section">
        <TableHead title="가입여부 확인" />
        <JoinInquiryFilterBar @onCheckJoinInfo="onCheckJoinInfo" @alert="alert" />
        <!-- <div v-if="isChecked" class="check_group">
          <div class="check_head">
            <div class="area_left">
              <button
                v-if="getAbledCompanies().length > 0"
                type="button"
                class="btn_secondary btn_medium"
                @click="onClickedAll"
              >
                {{ isClickedAll ? '선택 해제' : '전체선택' }}
              </button>
            </div>
            <div class="area_right">
              <div class="info_check_group">
                <span class="info_disabled"></span>
                <span class="txt_check_group">가입 공동체</span>
                <span class="info_abled"></span>
                <span class="txt_check_group">미가입 공동체</span>
              </div>
            </div>
          </div>
          <JoinInquiryPartnerList
            :companies="companies"
            :partnerJoins="partnerJoins"
            :afficodeList.sync="afficodeList"
          />
        </div> -->
      </div>
    </div>
    <Sticky v-if="isChecked">
      <button type="button" class="btn_fourthly btn_large" @click="goLogin">홈 화면</button>
      <button type="submit" class="btn_primary btn_large" @click="goJoin">회원가입</button>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import JoinInquiryFilterBar from "@/components/user/join/joinInquiry/JoinInquiryFilterBar";
import JoinInquiryPartnerList from "@/components/user/join/joinInquiry/JoinInquiryPartnerList";
import Sticky from "@/components/layout/content/Sticky.vue";

import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";

import { mapState } from "vuex";
import { COMPANY_ACTION } from "@/store/modules/company/action";

export default {
  name: "JoinInquiry",
  components: {
    PageWithLayout,
    TableHead,
    JoinInquiryFilterBar,
    JoinInquiryPartnerList,
    Sticky,
  },
  mixins: [PageMixin],
  data() {
    return {
      corporateNum: "",
      partnerJoins: [],
      afficodeList: [
        {
          appNum: 3,
          code: "std",
          companyCode: "std",
          companyKey: "std",
          companyName: "구매표준화",
          desc: "구매표준화",
        },
      ],
      ligAfficodeList: [
        {
          appNum: 3,
          code: "std",
          companyCode: "std",
          companyKey: "std",
          companyName: "LIG 시스템",
          desc: "LIG 시스템",
        },
      ],
      isClickedAll: false,
    };
  },
  mounted() {
    //this.$store.dispatch(COMPANY_ACTION.GET_COMPANY_LIST);
  },
  computed: {
    ...mapState({
      companies: (state) => state.company.companyList,
    }),
    isChecked() {
      return this.corporateNum !== "";
    },
  },
  methods: {
    goLogin() {
      this.$router.push({
        path: `${this.$routerPath.LOGIN}`,
      });
    },
    goJoin() {
      if (this.afficodeList.length < 1) {
        this.alert("회원가입 할 미가입 공동체를 선택해주세요.");
        return;
      }

      this.$router.push({
        name: this.$routerPath.JOIN,
        params: {
          corporateNum: this.corporateNum,
          afficodeList: !this.$isLIG ? this.afficodeList : this.ligAfficodeList,
        },
      });
    },
    async onCheckJoinInfo(corprateNumValue) {
      //, emailValue
      // 여기서 api 호출하게끔 변경.
      const url = `${this.$apiPath.PARTNER_JOIN_INQUIRY}?corprateNum=${corprateNumValue}&email=null`;
      //&email=${ emailValue }

      const result = await ApiService.shared.getData(url);

      if (result.code !== "200") {
        this.corporateNum = "";
        this.partnerJoins = [];

        this.alert(result.text);
        return;
      }

      this.corporateNum = corprateNumValue;

      this.partnerJoins = result.data && result.data.partnerJoins ? result.data.partnerJoins : [];

      if (result.data.partnerList.length > 0) {
        //front에서 체크함
        this.alert("이미 회원가입 하셨습니다.");
        this.corporateNum = "";
      }

      // 가입 내역 확인 클릭 시 초기화.
      //this.afficodeList = [];
      this.isClickedAll = false;
    },
    getIsJoinedAlready(companyCode) {
      return this.partnerJoins.some((item) => item.affiCode.toLowerCase() === companyCode);
    },
    getAbledCompanies() {
      const ret = this.companies.filter((item) => {
        return !this.getIsJoinedAlready(item.companyCode);
      });

      return ret;
    },
    onClickedAll() {
      this.isClickedAll = !this.isClickedAll;
      if (this.isClickedAll) {
        this.afficodeList = this.getAbledCompanies();
      } else {
        this.afficodeList = [];
      }
    },
  },
};
</script>

<style scoped>
.check_head {
  overflow: hidden;
  padding-bottom: 8px;
}
.info_check_group {
  padding-top: 6px;
}
.info_check_group .info_disabled {
  float: left;
  width: 56px;
  height: 16px;
  margin: 2px 8px 2px 0;
  border: 1px solid #e1e1e1;
}
.info_check_group .info_abled {
  float: left;
  width: 56px;
  height: 16px;
  margin: 2px 8px 2px 0;
  border: 1px solid #e1e1e1;
  background-color: #fffbed;
}
.info_check_group .txt_check_group {
  float: left;
  font-size: 14px;
  line-height: 20px;
  color: #888;
}
.info_check_group .txt_check_group + .info_abled {
  margin-left: 24px;
}
</style>
